<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <h1>{{ $t('message.service_subscription') }}</h1>
      </header>

      <p v-if="successMessage">
        <v-alert
          text
          dense
          color="teal"
          border="left"
          type="success"
        >
          {{ successMessage }}
        </v-alert>
      </p>

      <p v-if="errors.length">
        <v-alert
          text
          dense
          type="error"
          v-for="(error, index) in errors"
          :key="index"
        >
          {{ error.title }}
        </v-alert>
      </p>

      <div class="cards-container">
        <div class="demo-card-wide mdl-card mdl-card-custom" v-bind:class="{ 'is-active': subscribed }">
          <div class="mdl-card__title">
            <h2 class="mdl-card__title-text">{{ $t('message.dematerialization_of_pay_slips') }}</h2>
          </div>
          <div class="mdl-card__supporting-text">
            <p v-if="subscribed">
              {{ $t('message.subscribed_to_dematerialization') }}
            </p>
            <p v-if="!subscribed">
              {{ $t('message.not_subscribed_to_dematerialization') }}
            </p>
          </div>
          <div class="mdl-card__actions mdl-card--border">
            <span class="status">{{ subscribed ? $t('message.activated') : $t('message.deactivated') }}</span>
            <v-switch v-model="subscribed" v-on:change="putVault()"></v-switch>
          </div>
        </div>

        <div class="mdl-card-custom is-coming-soon">
          <i class="fab fa-codepen"></i>
          <h2 class="mdl-card__title-text">{{ $t('message.service_coming_soon') }}</h2>
        </div>

        <div class="mdl-card-custom is-coming-soon">
          <i class="fab fa-codepen"></i>
          <h2 class="mdl-card__title-text">{{ $t('message.service_coming_soon') }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

export default {
  name: 'ServicesSubscription',
  data: () => ({
    subscribed: false,
    successMessage: null,
    loading: false,
    errors: []
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    this.getVault()
    document.title = config.title + ' - ' + this.$t('message.service_subscription')
  },
  methods: {
    getVault () {
      const currentVault = this.$store.getters.getCurrentVault

      if (!currentVault) {
        return
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/vaults/' + currentVault.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const vault = response.data

            if (vault) {
              this.subscribed = vault.subscribed
            }
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false
        })
    },
    putVault () {
      const currentVault = this.$store.getters.getCurrentVault

      const data = {
        subscribed: this.subscribed
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/vaults/' + currentVault.id, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const vault = response.data

            if (vault) {
              this.subscribed = vault.subscribed

              localStorage.setItem('current_vault', JSON.stringify(vault))

              this.successMessage = this.$t('message.subscription_updated')
              this.errors = []
            }
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          this.loading = false
        })
    }
  }
}
</script>
